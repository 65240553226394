<template>
  <div class="changePswd">
    <!-- <div class="backBox" @click="toPage">
      {{i18nData.to}} <i class="el-icon-right"></i>
    </div> -->
    <div class="interest">
      <div v-if="!id">{{ i18nData.name }}</div>
      <div v-else>{{ i18nData.nameTwo }}</div>
      <div></div>
    </div>
    <div class="changeBox">
      <el-form
        class="formBox"
        :model="formData"
        :rules="rules"
        ref="formData"
        label-width="100px"
        :label-position="labelPosition"
      >
        <el-form-item
          class="formItem"
          prop="channel_id"
          :label="labelArr.channel"
        >
          <el-select
            class="select-search"
            v-model="formData.channel_id"
            :placeholder="labelArr.channeltip"
          >
            <el-option
              v-for="item in tabList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item class="formItem" prop="title" :label="i18nData.title">
          <el-input
            class="input"
            v-model="formData.title"
            :placeholder="i18nData.titletips"
            autocomplete="off"
          ></el-input>
        </el-form-item> -->
        <el-form-item class="formItem" prop="title" :label="labelArr.title">
          <el-input
            class="input"
            v-model="formData.title"
            :placeholder="labelArr.titletip"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          class="formItem"
          prop="title_us"
          :label="labelArr.title_us"
        >
          <el-input
            class="input"
            v-model="formData.title_us"
            :placeholder="labelArr.titletip_us"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          class="formItem"
          prop="title_us_short"
          :label="labelArr.title_us_short"
        >
          <el-input
            class="input"
            v-model="formData.title_us_short"
            :placeholder="labelArr.titletip_us_short"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item class="formItem" prop="cas" :label="labelArr.cas">
          <el-input
            class="input"
            v-model="formData.cas"
            :placeholder="labelArr.castip"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item class="formItem" prop="formula" :label="labelArr.formula">
          <el-input
            class="input"
            v-model="formData.formula"
            :placeholder="labelArr.formulatip"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          class="formItem"
          prop="category"
          :label="labelArr.category"
        >
          <el-input
            class="input"
            v-model="formData.category"
            :placeholder="labelArr.categorytip"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item class="formItem" label="内容">
          <div class="editor">
            <!-- <quill-editor
              v-model="formData.content"
              ref="myQuillEditor"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @change="onEditorChange($event)"
            >
            </quill-editor> -->
            <editor
              class="CKEditor"
              ref="editor"
              v-model="formData.content"
              @change="callbackChangeEditor"
              :getcontent="formData.content"
              :fileUrl="richTextFileUrl"
            ></editor>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="submitBox">
      <el-button
        class="submitBtn"
        type="primary"
        @click="submitForm('formData')"
        >{{ i18nData.button }}</el-button
      >
    </div>
  </div>
</template>

<script>
import editor from "./../components/editor.vue";
import { deb_, thr_ } from "./../common/util.js";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  name: "mypublish",
  components: {
    editor
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3|4|5|6|7|8][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入旧密码"));
      } else {
        // if (this.formData.oldPaswd !== "") {
        //   this.$refs.formData.validateField("oldPaswd");
        // }
        callback();
      }
    };
    var validateNewPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        // if (this.formData.newPaswd !== "") {
        //   this.$refs.formData.validateField("newPaswd");
        // }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入新密码"));
      } else if (value !== this.formData.newPaswd) {
        callback(new Error("两次输入新密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      id: "",
      // 图片上传url的方法
      richTextFileUrl: this.$api.baseUrl + "api/common/upload",
      labelPosition: "right",
      tabList: [],
      labelArr: {
        channel: "分类",
        channeltip: "请选择分类",
        title: "中文名称",
        titletip: "请输入中文名称",
        title_us: "英文名称",
        titletip_us: "请输入英文名称",
        title_us_short: "英文缩写",
        titletip_us_short: "请输入英文缩写",
        cas: "CAS号",
        castip: "请输入CAS号",
        formula: "分子式",
        formulatip: "请输入分子式",
        category: "药剂学类别",
        categorytip: "请输入药剂学类别"
      },
      formData: {
        diyname: "message",
        name: "",
        phone: "",
        email: "",
        need: "",
        channel_id: "",
        title: "",
        title_us: "",
        title_us_short: "",
        cas: "",
        formula: "",
        category: "",
        content: ""
      },
      rules: {
        channel_id: [
          { required: true, message: "请选择分类", trigger: "blur" }
        ],
        title: [{ required: true, message: "请输入中文名称", trigger: "blur" }],
        title_us: [
          { required: true, message: "请输入英文名称", trigger: "blur" }
        ],
        title_us_short: [
          { required: true, message: "请输入英文缩写", trigger: "blur" }
        ],
        content: [{ required: true, message: "请输入内容", trigger: "blur" }],
        phone: [{ required: true, validator: checkPhone, trigger: "blur" }],
        email: [
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: "blur"
          }
        ],
        need: [{ required: true, message: "请输入您的需求", trigger: "blur" }]
      },

      editorOption: {
        placeholder: "请在这里输入",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
            ["blockquote", "code-block"], //引用，代码块
            [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
            [{ list: "ordered" }, { list: "bullet" }], //列表
            [{ script: "sub" }, { script: "super" }], // 上下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            [{ direction: "rtl" }], // 文本方向
            [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
            [{ align: [] }], //对齐方式
            ["clean"], //清除字体样式
            ["image", "video"], //上传图片、上传视频
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
            [{ font: [] }] //字体
          ]
        }
      }
    };
  },
  created() {
    const id = this.$route.query.id;
    if (id) {
      this.id = id;
      this.getArchivesDetail();
    }
    this.$store.state.myActiveIndex = this.$route.name;
    this.getCategory();
  },
  methods: {
    toPage() {
      this.$router.replace({
        path: "/my/mySublist"
      });
    },
    submitForm: thr_(function(formName) {
      console.log(this.$refs[formName]);
      this.$refs[formName].validate(async valid => {
        if (valid) {
          let params = {
            title: this.formData.title,
            title_us: this.formData.title_us,
            title_us_short: this.formData.title_us_short,
            cas: this.formData.cas,
            formula: this.formData.formula,
            category: this.formData.category,
            content: this.formData.content,
            channel_id: this.formData.channel_id
          };
          if(this.id){
            params.id = this.id;
          }
          let res = await this.$api.https.archivesPost(params);
          this.$message({
            message: res.msg,
            duration: 2000
          });
          if (res.code) {
            setTimeout(() => {
              this.$router.replace({
                path: "/my/publish",
                query: { diyname: this.formData.diyname }
              });
            }, 1500);
          }
        } else {
          // console.log("error submit!!");
          this.$message({
            message: "请检查输入是否正确",
            type: "warning"
          });
          return false;
        }
      });
    }, 2000),
    async getCategory() {
      // 这里用try catch包裹，请求失败的时候就执行catch里的
      try {
        let params = {
          channel: "",
          menu_index: ""
        };
        let res = await this.$api.https.getCategory(params);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return;
        }
        if (res.code == 1) {
          const tabList = res.data.list;
          tabList.sort((a, b) => (a.id > b.id ? 1 : -1));
          this.tabList = tabList;
        } else {
          this.$message({
            message: res.msg
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getArchivesDetail() {
      let res = await this.$api.https.getArchivesDetail({
        id: this.id
      });
      if (!res.code) {
        return;
      }
      const detail = res.data.archivesInfo;
      this.formData.title = detail.title || "";
      this.formData.title_us = detail.title_us || "";
      this.formData.title_us_short = detail.title_us_short || "";
      this.formData.cas = detail.cas || "";
      this.formData.formula = detail.formula || "";
      this.formData.category = detail.category || "";
      this.formData.content = detail.content || "";
      this.formData.channel_id = detail.channel_id || "";
    },
    callbackChangeEditor(value) {
      this.formData.content = value;
    }
  }
};
</script>
<style lang="scss" scoped>
$vue_color: #0495f3;
@mixin interest($width) {
  .interest {
    text-align: center;
    height: 40px;
    width: $width;
    font-size: 32px;

    div:nth-child(2) {
      background: #cbe8ff;
      width: $width;
      height: 11px;
      border-radius: 5px;
      margin: -5px 0 0 0;
    }
  }
}
.changePswd {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 24px;
  text-align: left;
  padding: 20px 30px 60px 30px;
  .backBox {
    align-self: flex-end;
    font-size: 14px;
    color: $vue_color;
    display: flex;
    align-items: center;
    i {
      margin-left: 10px;
      font-size: 20px;
    }
  }
  .interest {
    margin: 20px 0 30px;
  }
  @include interest(350px);
  .changeBox {
    padding: 40px 0;
    width: 100%;
    // height: 500px;
    border-radius: 5px;
    .formBox {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .typeTabs {
        margin-bottom: 10px;
      }
      ::v-deep.el-tabs__nav-wrap::after {
        background-color: transparent;
      }
      .formItem {
        // margin-bottom: 5px;
        .input {
          width: 100%;
        }
        .editor {
          width: 100%;

          ::v-deep .quill-editor {
            .ql-container {
              min-height: 500px;
            }
          }
        }
        .input.textarea {
        }
        ::v-deep.el-input__inner {
          border-left: none;
          border-right: none;
          border-top: none;
          border-radius: 0;
        }
      }
    }
  }
  .submitBox {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 10vh;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;
    box-shadow: 2px 2px 20px 0 rgb(0 0 0 / 36%);
  }
  .submitBtn {
    margin-top: 30px;
    background-color: $vue_color;
    border-color: $vue_color;
    width: 120px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border-radius: 2px;
    color: #ffffff !important;
  }
}
.editor {
  line-height: normal !important;
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}
</style>
